import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from "../components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo';

const MdxPage = ({
  data: {
    mdx: {
      frontmatter: { title, path, description, date, author, ogImage, embeddedImagesRemote, embeddedImagesLocal },
      body,
    },
  },
}) => {
 
  let myOgg = {};
  myOgg.imagem = require('../../static/og-image/' + ogImage).default;

  const seoSwitch = () => {
    return (
        <Seo
            title={title}
            description={description}
            image={'https://www.tanoariacoelho-adrego.com' + myOgg.imagem}
            siteUrl={'https://www.tanoariacoelho-adrego.com' + path}
        />
    )
  }

  const frontmatter = {
    frontmatter: {
      title: title,
      path: path,
      description: description,
      date: date,
      author: author,
      ogImage: ogImage,
      remoteImages: embeddedImagesRemote,
      localImages: embeddedImagesLocal
  }};
  return (
    <Fragment>
      <Layout>

        {seoSwitch()}

        <MdxCoverLayout/>
        <MdxLayout>
          <MDXProvider>
            <MDXRenderer
              pageContext={frontmatter}
            >
              {body}
            </MDXRenderer>
          </MDXProvider>
        </MdxLayout>
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        path
        description
        date
        author
        ogImage
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;